/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class CalendarStore {

  loading: boolean = false;

  calendarSettingList: any = { totalCount: 0, data: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      calendarSettingList: observable,
      getCalendarSettingList: observable,
      // YEAR
      createSchoolYear: observable,
      updateSchoolYear: observable,
      deleteSchoolYear: observable,
      // SEMESTER
      createSchoolSemester: observable,
      updateSchoolSemester: observable,
      deleteSchoolSemester: observable,
      // TERM
      createSchoolTerm: observable,
      updateSchoolTerm: observable,
      deleteSchoolTerm: observable,
      // TERM DATE
      createSchoolTermDate: observable,
      updateSchoolTermDate: observable,
      deleteSchoolTermDate: observable,
    });

    this.rootStore = rootStore;
  }

  getCalendarSettingList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Calendar/GetCalendarSettingList/${take}/${skip}`);
      this.calendarSettingList = res.data;
      console.log("Calendar List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* ========== YEAR CRUD ========== */
  createSchoolYear = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Calendar/CreateSchoolYear`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateSchoolYear = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Calendar/UpdateSchoolYear`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteSchoolYear = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Calendar/DeleteSchoolYear/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END YEAR CRUD ========== */
  /* ========== SEMESTER CRUD ========== */
  createSchoolSemester = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Calendar/CreateSchoolSemester`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateSchoolSemester = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Calendar/UpdateSchoolSemester`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteSchoolSemester = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Calendar/DeleteSchoolSemester/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END SEMESTER CRUD ========== */
  /* ========== TERM CRUD ========== */
  createSchoolTerm = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Calendar/CreateSchoolTerm`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateSchoolTerm = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Calendar/UpdateSchoolTerm`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteSchoolTerm = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Calendar/DeleteSchoolTerm/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END TERM CRUD ========== */
  /* ========== TERM DATE CRUD ========== */
  createSchoolTermDate = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Calendar/CreateSchoolTermDate`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateSchoolTermDate = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Calendar/UpdateSchoolTermDate`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteSchoolTermDate = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Calendar/DeleteSchoolTermDate/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END TERM DATE CRUD ========== */
}
