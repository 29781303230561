import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import RootStore from './root-store';
import UserStore from './user-store';
import AcademicStore from './academic-store';
import CalendarStore from './calendar-store';
import QuestionStore from './question-store';
import TeacherStore from './teacher-store';
import PermissionStore from './permission-store';
import ExamStore from './exam-store';
import StudentStore from './student-store';
import GroupStore from './group-store';
import AdminStore from './admin-store';
import TraineeshipStore from './traineeship-store';
import FileStore from './file-store';

type CompositeStore = {
  rootStore: RootStore,
  userStore: UserStore,
  academicStore: AcademicStore,
  calendarStore: CalendarStore,
  questionStore: QuestionStore,
  teacherStore: TeacherStore,
  permissionStore: PermissionStore,
  studentStore: StudentStore,
  examStore: ExamStore,
  groupStore: GroupStore,
  adminStore: AdminStore,
  traineeshipStore: TraineeshipStore,
  fileStore: FileStore,
};

const isServer = typeof window === 'undefined';
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer);

// let _stores: null | CompositeStore = null;

const initStores = () => {
  const rootStore = new RootStore();
  const initializedStores: CompositeStore = {
    rootStore,
    userStore: rootStore.userStore,
    academicStore: rootStore.academicStore,
    calendarStore: rootStore.calendarStore,
    questionStore: rootStore.questionStore,
    teacherStore: rootStore.teacherStore,
    permissionStore: rootStore.permissionStore,
    studentStore: rootStore.studentStore,
    examStore: rootStore.examStore,
    groupStore: rootStore.groupStore,
    adminStore: rootStore.adminStore,
    traineeshipStore: rootStore.traineeshipStore,
    fileStore: rootStore.fileStore,
  };
  return initializedStores;
};

export const stores = initStores(); // _stores || initStores();

export const StoreContext = React.createContext(stores);

export const StoreProvider = ({ children }) => <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
