/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class AcademicStore {

  loading: boolean = false;

  loadingSubject: boolean = false;

  loadingCompulosories: boolean = false;

  academicSettingList: any = { totalCount: 0, data: [] };
  academicSettingBySemList: any = { totalCount: 0, data: [] };
  // Year Level
  yearLevelList: any = [];
  // Subject 
  subjectList: any = { totalCount: 0, data: [] };
  subjectListBySearchKey: any = { totalCount: 0, data: [] };
  // Subject Class
  subjectClassList: any = { totalCount: 0, data: [] };
  // Domain
  domainList: any = { totalCount: 0, data: [] };
  qualiBySearchKey: any = { totalCount: 0, data: [] };

  subjectDomainRelationList: any = { totalCount: 0, data: [] };

  // Qualification Related - Benchmark
  qualificationUnitSettingList: any = { totalCount: 0, data: [] };
  qualificationList: any = { totalCount: 0, data: [] };
  unitList: any = { totalCount: 0, data: [] };
  unitListBySearchKey: any = { totalCount: 0, data: [] };
  unitQualificationRelationList: any = { totalCount: 0, data: [] };
  unitQualificationRelationOptionList: any = { totalCount: 0, data: [] };
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingCompulosories: observable,
      academicSettingList: observable,
      academicSettingBySemList: observable,
      yearLevelList: observable,
      subjectList: observable,
      subjectListBySearchKey: observable,
      getAcademicSettingList: action,
      getAcademicSettingListBySemesterId: action,
      subjectClassList: observable,
      domainList: observable,
      qualiBySearchKey: observable,
      subjectDomainRelationList: observable,
      loadingSubject: observable,
      unitQualificationRelationOptionList: observable,
      searchQualificationByCode: action,
      // Year Level CRUD
      getYearLevelList: action,
      createYearLevel: action,
      updateYearLevel: action,
      deleteYearLevel: action,
      // Subject CRUD
      getSubjectList: action,
      getSubjectListBySearchKey: action,
      createSubject: action,
      updateSubject: action,
      deleteSubject: action,
      // Subject Class CRUD
      getSubjectClassListBySubjectId: action,
      createSubjectClass: action,
      updateSubjectClass: action,
      deleteSubjectClass: action,
      // Domain CRUD
      getDomainList: action,
      createDomain: action,
      updateDomain: action,
      deleteDomain: action,
      editDomainSubjectRelation: action,
      getSubjectListByDomainId: action,
      // Qualification - Benchmark
      qualificationList: observable,
      qualificationUnitSettingList: observable,
      unitQualificationRelationList: observable,
      unitList: observable,
      unitListBySearchKey: observable,
      getQualificationList: action,
      getQualificationUnitSetting: action,
      getUnitList: action,
      getUnitListByQualificationId: action,
      updateQualification: action,
      updateUnit: action,
      editQualificationUnitRelation: action,
      deleteQualification: action,
      deleteUnit: action,
    });

    this.rootStore = rootStore;
  }

  getAcademicSettingList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Academic/GetAcademicSettingList/${take}/${skip}`);
      this.academicSettingList = res.data;
      console.log("Academic Setting List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAcademicSettingListBySemesterId = async (semesterId: number, take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Academic/GetAcademicSettingListBySemesterId/${semesterId}/${take}/${skip}`);
      this.academicSettingBySemList = res.data;
      console.log("Academic Setting By Sem List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* ========== YEAR LEVEL CRUD ========== */
  getYearLevelList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Academic/GetYearLevelList`);
      this.yearLevelList = res.data;
      console.log("Year Level List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createYearLevel = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Academic/CreateYearLevel`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateYearLevel = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Academic/UpdateYearLevel`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteYearLevel = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Academic/DeleteYearLevel/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END YEAR LEVEL CRUD ========== */

  /* ========== SUBJECT CRUD ========== */
  getSubjectList = async (take: number, skip: number) => {
    this.loadingSubject = true;
    try {
      const res = await Axios.get(`/api/Academic/GetSubjectList/${take}/${skip}`);
      this.subjectList = res.data;
      console.log("Subject List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSubject = false;
    }
  }

  getSubjectListBySearchKey = async (searchStr: string, take: number, skip: number) => {
    this.loadingSubject = true;
    try {
      const res = await Axios.post(`/api/Academic/SearchSubjectByCode`, { searchStr, take, skip });
      this.subjectListBySearchKey = res.data;
      this.subjectList = res.data;
      console.log("Subject List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSubject = false;
    }
  }

  createSubject = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Academic/CreateSubject`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateSubject = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Academic/UpdateSubject`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteSubject = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Academic/DeleteSubject/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END SUBJECT CRUD ========== */

  /* ========== SUBJECT CLASS CRUD ========== */
  getSubjectClassListBySubjectId = async (id: number, take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Academic/GetSubjectClassListBySubjectId/${id}/${take}/${skip}`);
      this.subjectClassList = res.data;
      console.log("Subject Class List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createSubjectClass = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Academic/CreateSubjectClass`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateSubjectClass = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Academic/UpdateSubjectClass`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteSubjectClass = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Academic/DeleteSubjectClass/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END SUBJECT CLASS CRUD ========== */

  /* ========== DOMAIN CRUD ========== */

  getDomainList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Academic/GetDomainList/${take}/${skip}`);
      this.domainList = res.data;
      console.log("Domain List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createDomain = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Academic/CreateDomain`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateDomain = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Academic/UpdateDomain`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteDomain = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Academic/DeleteDomain/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  editDomainSubjectRelation = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Academic/EditDomainSubjectRelation`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getSubjectListByDomainId = async (id: number, take: number, skip: number) => {
    this.loadingCompulosories = true;
    try {
      const res = await Axios.get(`/api/Academic/getSubjectListByDomainId/${id}/${take}/${skip}`);
      this.subjectDomainRelationList = res.data;
      console.log("Domain/Subject List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingCompulosories = false;
    }
  }

  /* ========== END DOMAIN CRUD ========== */
  /* ========== ACADEMIC CRUD FOR BENCHMARK ========== */
  getQualificationUnitSetting = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AcademicQualificationUnit/GetQualificationUnitSetting/${take}/${skip}`);
      this.qualificationUnitSettingList = res.data;
      console.log("Qualification Setting List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getQualificationList = async (take: number, skip: number) => {
    this.loadingSubject = true;
    try {
      const res = await Axios.get(`/api/AcademicQualificationUnit/GetQualificationList/${take}/${skip}`);
      this.qualificationList = res.data;
      console.log("Qualification List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSubject = false;
    }
  }

  searchQualificationByCode = async (searchStr: string, take: number, skip: number) => {
    this.loadingSubject = true;
    try {
      const res = await Axios.post(`/api/AcademicQualificationUnit/SearchQualificationByCode`, { searchStr, take, skip });
      this.qualiBySearchKey = res.data;
      // this.qualificationList = res.data;
      console.log("Unit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSubject = false;
    }
  }

  getUnitList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AcademicQualificationUnit/GetUnitList/${take}/${skip}`);
      this.unitList = res.data;
      console.log("Unit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  searchUnitByCode = async (searchStr: string, take: number, skip: number) => {
    this.loadingSubject = true;
    try {
      const res = await Axios.post(`/api/AcademicQualificationUnit/SearchUnitByCode`, { searchStr, take, skip });
      this.unitListBySearchKey = res.data;
      // this.qualificationList = res.data;
      console.log("Unit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSubject = false;
    }
  }

  getUnitListByQualificationId = async (qualificationId: number, take: number, skip: number, asOption?: boolean) => {
    this.loadingCompulosories = true;
    try {
      const res = await Axios.get(`/api/AcademicQualificationUnit/GetUnitListByQualificationId/${qualificationId}/${take}/${skip}`);
      if (asOption) this.unitQualificationRelationOptionList = res.data;
      else this.unitQualificationRelationList = res.data;
      console.log("Unit/Quali List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingCompulosories = false;
    }
  }

  updateQualification = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/AcademicQualificationUnit/UpdateQualification`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteQualification = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/AcademicQualificationUnit/DeleteQualification/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateUnit = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/AcademicQualificationUnit/UpdateUnit`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteUnit = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/AcademicQualificationUnit/DeleteUnit/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  editQualificationUnitRelation = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AcademicQualificationUnit/EditQualificationUnitRelation`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
