import useStores from "../hooks/use-stores";

export const PAGE_SIZE = 8;

export enum Language {
  ENGLISH = 'en-AU',
  CHINESE_SIMP = 'zh-CN',
  CHINESE_TRAD = 'zh-TW'
}

export enum CRUDOperation {
  CREATE = 'create',
  UPDATE = 'Update',
  DELETE = 'Delete',
}

export const imageSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export enum DocumentType {
  TRAINER_PROFILE_FILE = "TrainerProfileFile",
  STUDENT_PROFILE_FILE = "StudentProfileFile",
  STUDENT_TRAINEESHIP_FILE = "StudentTraineeshipFile",
  VISIT_DOC = "VisitDocument",
}

export enum FileCategory {
  VISIT_DOC = "VisitDocument",
  FORM_SIGNATURE = "FormSignature",
  FORM_PERSONAL_ID = "FormPersonalId",
}

export enum TraineeshipTrainerStatus {
    ACCEPTED = "Accepted",
    REJECTED = "Rejected",
    NOT_ALLOCATED = "Please assign trainer",
    PENDING = "Wait for trainer's response",
}

export enum TraineeshipStatus {
    PENDING = "Wait to be approved",
    APPROVED = "Approved",
    COMPLETED = "Completed",
}

export enum FeedbackType {
  COMPETENT = "Competent",
  INCOMPETENT= "Incompetent",
  INCOMPLETED = "",
  CREDIT_TRANSFER = "Credit Transfer",
}

export enum NavParentCategory {
  DASHBOARD = 'Dashboard',
  ACADEMIC = 'Academic',
  QUESTION = 'Question',
  TEACHER = 'Teacher',
  STUDENT = 'Student',
  EXAM = 'Exam',
  CALENDAR = 'Calendar',
  GROUP = 'Group',
  TRAINEESHIP = 'Traineeship',
  OPERATOR = 'Admin',
}

export const isBrowser = typeof window !== "undefined";

export const Notify = (msg: string, variant: 'success' | 'error' | 'info' | 'warning') => {
  return { msg, variant };
}

export const NavListByCategory = {
  "Dashboard": [],
  "Academic": [{
    page: "Qualification",
    link: "/academic/domain-module"
  }, {
    page: "Unit",
    link: "/academic/subject-module"
  },
    //  {
    //   page: "Subject Class",
    //   link: "/academic/subject-class-module"
    // },
    // {
    //   page: "Year Level",
    //   link: "/academic/year-level-module"
    // }, 
  ],
  // "Question": [{
  //   page: "Overview",
  //   link: "/question/overview"
  // }, {
  //   page: "Type",
  //   link: "/question/type"
  // }, {
  //   page: "Category",
  //   link: "/question/category"
  // }, {
  //   page: "Knowledege Point",
  //   link: "/question/knowledge-point"
  // }],
  "Admin": [{
    page: "Overview",
    link: "/admin/overview"
  }],
  "Teacher": [{
    page: "Overview",
    link: "/teacher/overview"
  }, {
    page: "Teacher Profile",
    link: "/teacher/profile"
  }, {
    page: "Working Hours Summary",
    link: "/teacher/working-hours-summary"
  }],
  "Student": [{
    page: "Overview",
    link: "/student/overview"
  }, {
    page: "Student Profile",
    link: "/student/profile"
  },
    // {
    //   page: "Enrollment",
    //   link: "/student/enrollment"
    // },
    // {
    //   page: "Registration",
    //   link: "/student/registration"
    // }
  ],
  "Traineeship": [{
    page: "Overview",
    link: "/traineeship/overview",
  }, 
  // {
  //   page: "Trainee List",
  //   link: "/traineeship/registration-list",
  // },
    // {
    //   page: "Registration Form",
    //   link: "/traineeship/registration-form",
    // }
  ],
  "Exam": [{
    page: "Overview",
    link: "/exam/overview"
  }, {
    page: "Exam Setting",
    link: "/exam/exam-setting"
  }, {
    page: "Exam Questions",
    link: "/exam/exam-questions"
  }],
  // "Calendar": [],
  "Group": [{
    page: "Company Group",
    link: "/group/overview"
  }, {
    page: "Employer",
    link: "/group/organisation"
  }]
  // }, {
  //   page: "Visit List",
  //   link: "/group/visit-list"
  // }],
}

export enum ApiDataListType {
  YEAR_LEVEL = "YearLevel",
  ACADEMIC = "Academic",
  SUBJECT = "Subject",
  SUBJECT_SEARCH = "Subject Search",
  DOMAIN = "Domain",
  CALENDAR = "Calendar",
  QUESTION = "Question",
  QUESTION_TYPE = "QuestionType",
  QUESTION_CATEGORY = "QuestionCategory",
  QUESTION_OPTION = "QuestionOption",
  QUESTION_KNOWLEDEGE_PT = "QuestionKnowledegePoint",
  TEACHER = "Teacher",
  PERMISSION_ROLE = "PermissionRole",
  STUDENT = "Student",
  EXAM = "Exam",
  GROUP = "Group",
  TRAINEESHIP = "Traineeship",
  OPERATOR = "Admin",
}

export const IsLoggedIn = () => {
  const { userStore } = useStores();
  return userStore.isAuthed && userStore.userToken !== "" && (
    typeof window != undefined && localStorage.USER_TOKEN && localStorage.USER_INFO);
}

export const ProdEnvCheck = (dataList: any) => {
  if (typeof window !== 'undefined') {
    for (var data of dataList) if (!data) return false;
    return true;
  }
  return false;
}

export const GetCollegeId = () => {
  if (isBrowser && ProdEnvCheck([localStorage.USER_INFO]) && JSON.parse(localStorage.USER_INFO).collegeId) {
    return JSON.parse(localStorage.USER_INFO).collegeId;
  }
  return 0;
}

export const GetUserId = () => {
  if (isBrowser && ProdEnvCheck([localStorage.USER_INFO]) && JSON.parse(localStorage.USER_INFO).id) {
    return JSON.parse(localStorage.USER_INFO).id;
  }
  return 0;
}

export const GetCurrentSemester = () => {
  if (isBrowser && ProdEnvCheck([localStorage.CALENDAR])) {
    return JSON.parse(localStorage.CALENDAR);
  }
  return 0;
}

export const ApiDataResponseListLengthCheck = (listType: ApiDataListType) => {
  const { academicStore, calendarStore, questionStore, teacherStore, permissionStore, studentStore, examStore, groupStore, adminStore } = useStores();
  // Academic
  if (listType === ApiDataListType.YEAR_LEVEL) return academicStore.yearLevelList.length;
  if (listType === ApiDataListType.SUBJECT) return academicStore.unitList.totalCount;
  if (listType === ApiDataListType.SUBJECT_SEARCH) return academicStore.unitListBySearchKey.totalCount;
  if (listType === ApiDataListType.ACADEMIC) return academicStore.qualificationUnitSettingList.totalCount;
  if (listType === ApiDataListType.DOMAIN) return academicStore.qualificationList.totalCount;
  // School Calendar
  if (listType === ApiDataListType.CALENDAR) return calendarStore.calendarSettingList.totalCount;
  // Question
  if (listType === ApiDataListType.QUESTION) return questionStore.questionList.length;
  if (listType === ApiDataListType.QUESTION_TYPE) return questionStore.questionTypeList.length;
  if (listType === ApiDataListType.QUESTION_CATEGORY) return questionStore.questionCatList.length;
  // Teacher
  if (listType === ApiDataListType.TEACHER) return teacherStore.teacherProfileList.totalCount;
  // Permission
  if (listType === ApiDataListType.PERMISSION_ROLE) return permissionStore.roleDetailList.length;
  // Student
  if (listType === ApiDataListType.STUDENT) return studentStore.studentProfileList.totalCount;
  // Exam
  if (listType === ApiDataListType.EXAM) return examStore.examList.totalCount;
  // Group
  if (listType === ApiDataListType.GROUP) return groupStore.groupList.totalCount;
  // Admin
  if (listType === ApiDataListType.OPERATOR) return adminStore.adminProfileList.totalCount;

}

