/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { USIBackendURL } from '../constants/settings';
import { DateUtils } from '../utilities/date';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class TraineeshipStore {

  loading: boolean = false;

  verifyingUSI: boolean = false;

  resending: boolean = false;

  requestingExtension: boolean = false;

  updatingUSI: boolean = false;

  downloadExcel: boolean = false;

  formInfo: any = null;

  trainerListByQuaId: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      verifyingUSI: observable,
      resending: observable,
      downloadExcel: observable,
      updatingUSI: observable,
      formInfo: observable,
      trainerListByQuaId: observable,
      requestingExtension: observable,
      // student enrol
      getFormById: action,
      createEnrollForm: action,
      submitEnrollForm: action,
      deleteEnrollForm: action,
      updateForm: action,
      // traineeship
      getTrainerListByQualificationId: action,
      updateContractInfo: action,
      assignTrainerToTraineeship: action,
      createContractExtension: action,
      updateContractExtensionStatus: action,
      updateTCIDStatus: action,
      approveTraineeship: action,
      completeTraineeship: action,
      generateTraineeEnrollOverviewExcel: action,
      updateUSI: action,
    });

    this.rootStore = rootStore;
  }

  /* ========== STUDENT ENROLLMENT CRUD ========== */
  getFormById = async (formId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/StudentEnroll/GetFormById/${formId}`);
      this.formInfo = res.data;
      console.log("Form Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createEnrollForm = async (traineeId: number) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/StudentEnroll/CreateEnrollForm/${traineeId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  submitEnrollForm = async (formId: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/StudentEnroll/SubmitEnrollForm/${formId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteEnrollForm = async (formId: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/StudentEnroll/DeleteEnrollForm/${formId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateForm = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/StudentEnroll/UpdateForm`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END STUDENT ENROL CRUD ========== */
  // ========== TRAINIEEESHIP CRUD ============ * /
  updateContractInfo = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Traineeship/UpdateContractInfo`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  assignTrainerToTraineeship = async (trainerId: number, traineeshipId: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Traineeship/AssignTrainerToTraineeship/${trainerId}/${traineeshipId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateTCIDStatus = async (id: number, status: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Traineeship/updateTCIDStatus/${id}/${status}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getTrainerListByQualificationId = async (qualificationId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Traineeship/getTrainerListByQualificationId/${qualificationId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createContractExtension = async (reqModel: any) => {
    this.requestingExtension = true;
    try {
      const res = await Axios.post(`/api/Traineeship/CreateContractExtension`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.requestingExtension = false;
    }
  }

  updateContractExtensionStatus = async (extensionId: number, status: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Traineeship/UpdateContractExtensionStatus/${extensionId}/${status}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  approveTraineeship = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Traineeship/ApproveTraineeship/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  completeTraineeship = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Traineeship/CompleteTraineeship`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  generateTraineeEnrollOverviewExcel = async (reqModel: any) => {
    this.downloadExcel = true;
    try {
      const requestURL = `/api/StudentEnroll/GenerateTraineeEnrollOverviewExcel`;
      const res = await Axios.post(requestURL, reqModel, { responseType: 'blob' });
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = `${DateUtils.onConvertDateFormat(
        reqModel.startDate).replace('/', '-').replace('/', '-')}_${DateUtils.onConvertDateFormat(
          reqModel.endDate.split('T')[0]).replace('/', '-').replace('/', '-')}_TraineeEnrollFormOverviewReport.xls`
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href)
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloadExcel = false;
    }
  }

  verifyUSI = async (reqModel: any) => {
    this.verifyingUSI = true;
    try {
      const res = await axios.post(`${USIBackendURL}/api/USI/VerifyUSI`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.verifyingUSI = false;
    }
  }

  resendVerifyEmail = async (email: string) => {
    this.resending = true;
    try {
      const res = await Axios.get(`/api/Auth/ResendVerifyEmail/${email}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.resending = false;
    }
  }

  updateUSI = async (reqModel: any) => {
    this.updatingUSI = true;
    try {
      const res = await Axios.post(`/api/StudentEnroll/UpdateUSI`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updatingUSI = false;
    }
  }
}
