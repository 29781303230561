/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class QuestionStore {

  loading: boolean = false;

  loadingQuestionSetting: boolean = false;

  questionList: any = [];

  questionTypeList: any = [];

  questionCatList: any = { totalCount: 0, data: [] };

  singleQuestionInfo: any | null = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingQuestionSetting: observable,
      questionList: observable,
      questionTypeList: observable,
      questionCatList: observable,
      singleQuestionInfo: observable,
      // Question Overview CRUD
      getQuestionListBySubjectId: action,
      createQuestion: action,
      updateQuestion: action,
      deleteQuestion: action,
      // Question Cat CRUD
      getQuestionCategoryListBySubjectId: action,
      createQuestionCategory: action,
      updateQuestionCategory: action,
      deleteQuestionCategory: action,
      // Question Type CRUD
      getQuestionType: action,
      createQuestionType: action,
      updateQuestionType: action,
      deleteQuestionType: action,
      // Question Option CRUD
      createQuestionOption: action,
      updateQuestionOption: action,
      deleteQuestionOption: action,
      // Question Knowlege Point CRUD
      createQuestionKnowledgePoint: action,
      updateQuestionKnowledgePoint: action,
      deleteQuestionKnowledgePoint: action,
      onFilterTargetQuestion: action,
    });

    this.rootStore = rootStore;
  }

  /* ========== QUESTION OVERVIEW CRUD ========== */
  getQuestionListBySubjectId = async (id: number) => {
    this.loadingQuestionSetting = true;
    try {
      const res = await Axios.get(`/api/Question/GetQuestionListBySubjectId/${id}`);
      this.questionList = res.data;
      this.questionCatList = { totalCount: res.data[0].questionCategories.length, data: res.data[0].questionCategories };
      console.log("Question List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingQuestionSetting = false;
    }
  }

  createQuestion = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Question/CreateQuestion`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateQuestion = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Question/UpdateQuestion`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteQuestion = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Question/DeleteQuestion/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* ========== END QUESTION OVERVIEW CRUD ========== */
  /* ========== QUESTION CATERGORY CRUD ========== */

  getQuestionCategoryListBySubjectId = async (id: number, take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Question/getQuestionCategoryListBySubjectId/${id}/${take}/${skip}`);
      this.questionCatList = res.data;
      console.log("Question Category List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  createQuestionCategory = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Question/CreateQuestionCategory`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateQuestionCategory = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Question/UpdateQuestionCategory`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteQuestionCategory = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Question/DeleteQuestionCategory/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* ========== END QUESTION OVERVIEW CRUD ========== */
  /* ========== QUESTION TYPE CRUD ========== */

  getQuestionType = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Question/GetQuestionTypeList`);
      this.questionTypeList = res.data;
      console.log("Question Type List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createQuestionType = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Question/CreateQuestionType`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateQuestionType = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Question/UpdateQuestionType`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteQuestionType = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Question/DeleteQuestionType/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END QUESTION TYPE CRUD ========== */
  /* ========== QUESTION OPTION CRUD ========== */

  createQuestionOption = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Question/CreateQuestionOption`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateQuestionOption = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Question/UpdateQuestionOption`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteQuestionOption = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Question/DeleteQuestionOption/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END QUESTION OPTION CRUD ========== */
  /* ========== KNOWLEDEGE POINT CRUD ========== */

  createQuestionKnowledgePoint = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Question/CreateQuestionKnowledgePoint`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateQuestionKnowledgePoint = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Question/UpdateQuestionKnowledgePoint`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteQuestionKnowledgePoint = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Question/DeleteQuestionKnowledgePoint/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  onFilterTargetQuestion = (
    categoryId: number, knowledegeId: number, questionId: number) => {
    this.singleQuestionInfo = this.questionCatList.data.find(qc => qc.id == categoryId)
      .questionKnowledgePoints.find(kp => kp.id == knowledegeId)
      .questionLists.find(q => q.id == questionId);
      console.log(toJS(this.singleQuestionInfo));
      
  }
}
