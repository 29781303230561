/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class PermissionStore {

  loading: boolean = false;

  roleDetailList: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      roleDetailList: observable,
    });

    this.rootStore = rootStore;
  }

  /* ========== ROLE   CRUD ========== */
  getUserRoleDetailList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Permission/GetUserRoleDetailList`);
      this.roleDetailList = res.data;
      console.log("Role Detail List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createUserRole = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Permission/CreateUserRole`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateUserRole = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Permission/UpdateUserRole`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteUserRole = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Permission/DeleteUserRole`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END ROLE CRUD ========== */
}
