exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academic-domain-module-old-tsx": () => import("./../../../src/pages/academic/domain-module-old.tsx" /* webpackChunkName: "component---src-pages-academic-domain-module-old-tsx" */),
  "component---src-pages-academic-domain-module-tsx": () => import("./../../../src/pages/academic/domain-module.tsx" /* webpackChunkName: "component---src-pages-academic-domain-module-tsx" */),
  "component---src-pages-academic-subject-class-module-tsx": () => import("./../../../src/pages/academic/subject-class-module.tsx" /* webpackChunkName: "component---src-pages-academic-subject-class-module-tsx" */),
  "component---src-pages-academic-subject-module-old-tsx": () => import("./../../../src/pages/academic/subject-module-old.tsx" /* webpackChunkName: "component---src-pages-academic-subject-module-old-tsx" */),
  "component---src-pages-academic-subject-module-tsx": () => import("./../../../src/pages/academic/subject-module.tsx" /* webpackChunkName: "component---src-pages-academic-subject-module-tsx" */),
  "component---src-pages-academic-year-level-module-tsx": () => import("./../../../src/pages/academic/year-level-module.tsx" /* webpackChunkName: "component---src-pages-academic-year-level-module-tsx" */),
  "component---src-pages-admin-overview-tsx": () => import("./../../../src/pages/admin/overview.tsx" /* webpackChunkName: "component---src-pages-admin-overview-tsx" */),
  "component---src-pages-calendar-index-tsx": () => import("./../../../src/pages/calendar/index.tsx" /* webpackChunkName: "component---src-pages-calendar-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-exam-exam-questions-tsx": () => import("./../../../src/pages/exam/exam-questions.tsx" /* webpackChunkName: "component---src-pages-exam-exam-questions-tsx" */),
  "component---src-pages-exam-exam-setting-tsx": () => import("./../../../src/pages/exam/exam-setting.tsx" /* webpackChunkName: "component---src-pages-exam-exam-setting-tsx" */),
  "component---src-pages-exam-overview-tsx": () => import("./../../../src/pages/exam/overview.tsx" /* webpackChunkName: "component---src-pages-exam-overview-tsx" */),
  "component---src-pages-group-organisation-tsx": () => import("./../../../src/pages/group/organisation.tsx" /* webpackChunkName: "component---src-pages-group-organisation-tsx" */),
  "component---src-pages-group-overview-tsx": () => import("./../../../src/pages/group/overview.tsx" /* webpackChunkName: "component---src-pages-group-overview-tsx" */),
  "component---src-pages-group-visit-list-tsx": () => import("./../../../src/pages/group/visit-list.tsx" /* webpackChunkName: "component---src-pages-group-visit-list-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-question-category-tsx": () => import("./../../../src/pages/question/category.tsx" /* webpackChunkName: "component---src-pages-question-category-tsx" */),
  "component---src-pages-question-knowledge-point-tsx": () => import("./../../../src/pages/question/knowledge-point.tsx" /* webpackChunkName: "component---src-pages-question-knowledge-point-tsx" */),
  "component---src-pages-question-overview-index-tsx": () => import("./../../../src/pages/question/overview/index.tsx" /* webpackChunkName: "component---src-pages-question-overview-index-tsx" */),
  "component---src-pages-question-overview-question-option-tsx": () => import("./../../../src/pages/question/overview/question-option.tsx" /* webpackChunkName: "component---src-pages-question-overview-question-option-tsx" */),
  "component---src-pages-question-type-index-tsx": () => import("./../../../src/pages/question/type/index.tsx" /* webpackChunkName: "component---src-pages-question-type-index-tsx" */),
  "component---src-pages-student-enrollment-tsx": () => import("./../../../src/pages/student/enrollment.tsx" /* webpackChunkName: "component---src-pages-student-enrollment-tsx" */),
  "component---src-pages-student-overview-tsx": () => import("./../../../src/pages/student/overview.tsx" /* webpackChunkName: "component---src-pages-student-overview-tsx" */),
  "component---src-pages-student-profile-tsx": () => import("./../../../src/pages/student/profile.tsx" /* webpackChunkName: "component---src-pages-student-profile-tsx" */),
  "component---src-pages-student-registration-tsx": () => import("./../../../src/pages/student/registration.tsx" /* webpackChunkName: "component---src-pages-student-registration-tsx" */),
  "component---src-pages-teacher-overview-tsx": () => import("./../../../src/pages/teacher/overview.tsx" /* webpackChunkName: "component---src-pages-teacher-overview-tsx" */),
  "component---src-pages-teacher-profile-old-tsx": () => import("./../../../src/pages/teacher/profile-old.tsx" /* webpackChunkName: "component---src-pages-teacher-profile-old-tsx" */),
  "component---src-pages-teacher-profile-tsx": () => import("./../../../src/pages/teacher/profile.tsx" /* webpackChunkName: "component---src-pages-teacher-profile-tsx" */),
  "component---src-pages-teacher-working-hours-summary-tsx": () => import("./../../../src/pages/teacher/working-hours-summary.tsx" /* webpackChunkName: "component---src-pages-teacher-working-hours-summary-tsx" */),
  "component---src-pages-traineeship-overview-tsx": () => import("./../../../src/pages/traineeship/overview.tsx" /* webpackChunkName: "component---src-pages-traineeship-overview-tsx" */),
  "component---src-pages-traineeship-registration-form-tsx": () => import("./../../../src/pages/traineeship/registration-form.tsx" /* webpackChunkName: "component---src-pages-traineeship-registration-form-tsx" */),
  "component---src-pages-traineeship-registration-list-tsx": () => import("./../../../src/pages/traineeship/registration-list.tsx" /* webpackChunkName: "component---src-pages-traineeship-registration-list-tsx" */),
  "component---src-pages-traineeship-summary-page-print-tsx": () => import("./../../../src/pages/traineeship/summary-page-print.tsx" /* webpackChunkName: "component---src-pages-traineeship-summary-page-print-tsx" */)
}

