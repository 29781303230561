/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class GroupStore {

  loading: boolean = false;

  loadingOrg: boolean = false;

  loadingStudentList: boolean = false;

  loadingVisitList: boolean = false;

  loadingFile: boolean = false;

  loadingTraineeship: boolean = true;

  loadingVisitTable: boolean = false;

  loadingVisit: boolean = false;

  groupList: any = { totalCount: 0, data: [] };

  organisationList: any = { totalCount: 0, data: [] };

  orgStudentList: any = { totalCount: 0, data: [] };

  classStudentList: any = { totalCount: 0, data: [] };

  currentStudentProfile: any = {};

  visitList: any = { totalCount: 0, data: [] };

  visitChangeList: any = { totalCount: 0, data: []};

  traineeshipVisitList: any = { totalCount: 0, data: []};

  visitInDateRangeList: any = { totalCount: 0, data: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingOrg: observable,
      loadingStudentList: observable,
      loadingVisitList: observable,
      loadingVisit: observable,
      loadingFile: observable,
      loadingTraineeship: observable,
      loadingVisitTable: observable,
      groupList: observable,
      organisationList: observable,
      orgStudentList: observable,
      classStudentList: observable,
      currentStudentProfile: observable,
      visitList: observable,
      visitInDateRangeList: observable,
      visitChangeList: observable,
      traineeshipVisitList: observable,
      getGroupList: action,
      createGroup: action,
      updateGroup: action,
      deleteGroup: action,
      createOrganisation: action,
      updateOrganisation: action,
      deleteOrganisation: action,
      getOrganisationListByGroupId: action,
      getOrganisationStudentListByOrgId: action,
      getStudentListByClassId: action,
      createOrganisationStudent: action,
      updateOrganisationStudent: action,
      deleteOrganisationStudent: action,
      getVisitListByOrgId: action,
      createVisit: action,
      updateVisit: action,
      deleteVisit: action,
      getUploadedFile: action,
      setVisitTableLoading: action,
      getVisitListByTraineeshipId: action,
      getStudentProfileById: action,
    });

    this.rootStore = rootStore;
  }

  setVisitTableLoading = (flag: boolean) => {
    this.loadingVisitTable = flag;
  }

  /* ========== GROUP CRUD ========== */
  getGroupList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Group/GetGroupList/${take}/${skip}`);
      this.groupList = res.data;
      console.log("Group Profile List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  

  createGroup = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Group/CreateGroup`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateGroup = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Group/UpdateGroup`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteGroup = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Group/DeleteGroup/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END GROUP CRUD ========== */
  /* ========== VIEW ORGANISATION CRUD ========== */
  getOrganisationListByGroupId = async (id: number, take: number, skip: number) => {
    this.loadingOrg = true;
    try {
      const res = await Axios.get(`/api/Organisation/GetOrganisationListByGroupId/${id}/${take}/${skip}`);
      this.organisationList = res.data;
      console.log("Organisation List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingOrg = false;
    }
  }

  createOrganisation = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Organisation/CreateOrganisation`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateOrganisation = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Organisation/UpdateOrganisation`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteOrganisation = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Organisation/DeleteOrganisation/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END ORGANISATION CRUD ========== */
  /* ========== VIEW ORGANISATION STUDENT CRUD ========== */
  getOrganisationStudentListByOrgId = async (id: number, take: number, skip: number) => {
    this.loadingStudentList = true;
    try {
      const res = await Axios.get(`/api/Organisation/GetStudentProfileListByOrgId/${id}/${take}/${skip}`);
      this.orgStudentList = res.data;
      console.log("Organisation Student List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingStudentList = false;
    }
  }

  getStudentListByClassId = async (id: number, take: number, skip: number) => {
    this.loadingStudentList = true;
    try {
      const res = await Axios.get(`/api/Organisation/GetStudentListByClassId/${id}/${take}/${skip}`);
      this.classStudentList = res.data;
      console.log("Class Student List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingStudentList = false;
    }
  }

  createOrganisationStudent = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Organisation/CreateOrganisationStudent`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateOrganisationStudent = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Organisation/UpdateOrganisationStudent`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteOrganisationStudent = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Organisation/DeleteOrganisationStudent/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END ORGANISATION STUDENT CRUD ========== */
  /* ==========  VISIT CRUD ========== */

  getVisitListByOrgId = async (id: number, take: number, skip: number) => {
    this.loadingVisitList = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByOrgId/${id}/${take}/${skip}`);
      this.visitList = res.data;
      console.log("Visit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitList = false;
    }
  }

  createVisit = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Visit/CreateVisit`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateVisit = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Visit/UpdateVisit`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteVisit = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Visit/DeleteVisit/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getVisitListByDateRange = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Visit/GetVisitListByDateRange`, reqModel);
      this.visitInDateRangeList = res.data;
      console.log("Visit In Date Range List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getUploadedFile = async (visitId: any, name: string) => {
    this.loadingFile = true;
    try {
      const res = await Axios.get(`api/File?visitId=${visitId}&name=${name}`
        , { responseType: "blob" });
      console.log("Upload File List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.resolve(err);
    } finally {
      this.loadingFile = false;
    }
  }

  getChangeRequestListByVisitId = async(id: number) => {
    this.loadingVisitTable = true;
    try {
      const res = await Axios.get(`/api/Visit/GetChangeRequestListByVisitId/${id}`, {headers: {ContentType: "application/json"}})
      console.log("Visit Change List", res.data);
      this.visitChangeList.data = res.data;
      this.visitChangeList.totalCount = res.data.length;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitTable = false;
    }
  }

  // get visit list by traineeship id
  getVisitListByTraineeshipId = async (traineeshipId: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByTraineeshipId/${traineeshipId}`);
      this.traineeshipVisitList.totalCount = res.data.length
      this.traineeshipVisitList.data= res.data
      console.log("visit list by traineeship Id: ", res.data)
      return Promise.resolve(res.data)
    } catch(err) {
      return Promise.reject(err)
    } finally {
      this.loadingVisit = false;
    }
  }

  getStudentProfileById = async(traineeId: number) => {
    this.loadingTraineeship = true;
    try {
      const res = await Axios.get(`/api/Student/GetStudentProfileById/${traineeId}`)
      console.log("Student Profile By Id: ", res.data)
      this.currentStudentProfile = res.data
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      this.loadingTraineeship = false;
    }
  }

}
