/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class AdminStore {

  loading: boolean = false;

  adminProfileList: any = { totalCount: 0, data: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      adminProfileList: observable,

      getAdminProfileList: action,
      updateAdminProfile: action,
      deleteAdminProfile: action,
    });

    this.rootStore = rootStore;
  }

  /* ========== ADMIN CRUD ========== */
  getAdminProfileList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Admin/GetAdminProfileList/${take}/${skip}`);
      this.adminProfileList = res.data;
      console.log("Admin Profile List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateAdminProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Admin/UpdateAdminProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteAdminProfile = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Admin/DeleteAdminProfile/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END ROLE CRUD ========== */
}
