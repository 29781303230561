
const login = {
  SIGN_IN: '登录',
  SIGN_IN_NOW: '登录界面',
  WELCOME_TO_THE_SYSTEM: '欢迎来到学校管理系统',
  FORGOT_PASSWORD: '忘记密码',
  USERNAME: '用户名',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认您的密码',
  TWO_PASSWORDS_NOT_MATCH: '已输入密码不匹配',
  INCORRECT_USERNAME_PASSWORD: '用户名/密码错误',
  EMAIL: '邮箱',
  CONFIRM_EMAIL: '确认您的邮箱',
  TWO_EMAILS_NOT_MATCH: '已输入邮箱不匹配',
  INVALID_EMAIL: '此邮箱地址无效',
  SEND_VERIFICATION_EMAIL: '发送验证邮件',
  VERIFICATION_EMAIL_SENT: '验证邮件已发送',
  RESET_PASSWORD: '重置密码',
  RESET_YOUR_PASSWORD: '重置您的密码',
  RESET_PASSWORD_FAILED: '重置密码失败',
  VERIFY_URL_LINK: '验证URL链接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '您确定现在登出吗',
  VALIDATING_USER_CREDENTIAL: '验证身份信息中',
  BYE: '再会',
  WELCOME_TO_AUOKKA: '欢迎来到Auokka教育管理系统',
}

const menu = {
  HI: "你好",
  DASHBOARD: '仪表板',
  STUDENT: '学生管理',
  TEACHER: '老师管理',
  ACADEMIC: '科目配置',
  QUESTION: '考题分配',
  EXAM: '考试系统',
  CALENDAR: '日期设置',
  GROUP: '集团配置',
  SUBJECT: '课程',
  SUBJECT_CLASS: '班级',
  DOMAIN: '科目',
  QUESTION_TYPE: '题目类型',
  QUESTION_CATEGORY: '课题类别',
  KNOWLEDEGE_POINT: '知识点',
  OVERVIEW: '概览',
  ENROLLMENT: '课程注册',
  EXAM_SETTING: '考试设置',
  EXAM_QUESTIONS: '考题',
  ORGANISATION: '教育机构',
  VISIT_LIST: '授课列表',
}

const dashboard = {
  MSG_LIST: '系统消息列表',
  TYPE: '类别',
  DESCRIPTION: '详情',
  SENDER: '发件人',
  RECEIVER: '收件人',
  SENT_TIME: '发送时间',
  ACTION: '操作',
  VIEW_DETAIL: '浏览详情',
  MSG_DETAIL: '消息详情',
  STUDENTS: '学生',
  TEACHERS: '老师',
  ORGANISATIONS: '教育机构',
  TEACHER_VISITS: '老师约课数',
  ORGANISATION_VISITS: '机构约课数',
  LAST_YEAR: '去年',
  UNREAD: '未读',
}

const yearLevel = {
  MODULE_B: '有关',
  LIST_B: '列表',
  ADD_A: '新增',
  DESCRIPTION_B: '详情',
  UPDATE_A: '更新',
  CONFIRM_A: '确认',
  CREATION: '新增',
  UPDATE_NOW: '更新',
  CREATE_NOW: '新增',
  DELETE_A: '删除',
  CONFIRMATION: '确认',
  CONFIRM_DELETE: '确认删除',
  DELETE_NOW: '删除',
  DELETE_CHECK: '你确认删除此条记录',
  DELETE_INFO_YEAR_LEVEL: '所有与此条记录有关数据（如课程，科目等等）将会一并删除',
  EDIT: '编辑',
  UPDATE: '更新',
}

const subject = {
  SUBJECT_CODE: '课程代号',
  SETTING_B: '设置',
  NOT_SELECTED: '未选择',
  DELETE_INFO_SUBJECT: '所有与此条记录有关数据（如班级等等）将会一并删除'
}

const subjectClass = {
  CLASS_CODE: '班号',
  CLASS_IDENTIFIER: '班级ID',
  DELETE_INFO_SUBJECT_CLASS: '所有与此条记录有关数据将会一并删除'
}

const domain = {
  CODE_B: '号',
  DELETE_INFO_DOMAIN: '所有与此条记录有关数据（如课程等等）将会一并删除',
  LINK_SUBJECTS: '链接课程',
  LINK_DOMAIN_SUBJECT: '链接科目与课程',
  STEP_1_SELECT: '第一步：从下方列表选择课程',
  STEP_2_EDIT_SELECT: "第二步：编辑已选择的课程必修状态",
  LINK_CONFIRM: '确认链接',
  LINK_NOW: '链接',
  TOGGLE_COMPULSORY_STATUS: '切换课程必修状态（开启 = 必修课， 关闭 = 非必修课）',
}

const question = {
  OVERVIEW_B: '总览',
  CATEGORY: '课题类别',
  DIFFICULTY: '难度',
  VIEW_OPTIONS: '查看答案选项',
  QUESTION_TYPE: '题目类型',
  NO_QUESTION_CAT_FOUND: '暂无课题类别',
  NO_KNW_PT_FOUND: '暂无知识点',
  DELETE_INFO_QUESTION: '所有与此条记录有关数据（如学生答案和分数等等）将会一并删除',
  DETAIL: '详情',
  QUESTION_OPTIONS: '答案选项',
  OPTION: '选项',
  OPTION_DETAIL: '选项详情',
  IS_CORRECT: '是否为正确答案',
  CORRECT_ANSWER: '正确答案',
  DELETE_INFO_EXAM: '所有与此条记录有关数据（如测验记录等等）将会一并删除',
  SEARCH_SUBJECT: '搜索课程',
  SUBJECT_COUNT: '课程数量',
}

const questionType = {
  TYPE: '类别',
  DELETE_INFO_QUESTION_TYPE: '所有与此条记录有关数据（如所属题目等等）将会一并删除'
}

const questionCat = {
  QUESTION_CATEGORY: '课题类别',
  DELETE_INFO_QUESTION_CAT: '所有与此条记录有关数据（如所属题目等等）将会一并删除',
}

const knowledgePoint = {
  DELETE_INFO_KNOWLEDGE_PT: '所有与此条记录有关数据（如所属题目等等）将会一并删除',
}

const exam = {
  START_TIME: '开始时间',
  END_TIME: '结束时间',
  SETTING: '设置',
  TIME_LIMIT: '时限',
  DISPLAY_KNOWLEGE_PT: '测验中显示知识点',
  ENABLE_PARTIAL_MARK_IN_MP: '多选题允许计算部分分数',
  ENABLE_SAME_QUESTION_EXAM: '允许重复题目',
  INFINITE_ATTEMPTS_ALLOWED: '允许无限次尝试',
  ATTEMPT_ALLOW: '测验尝试次数',
  DELETE_INFO_EXAM: '所有与此条记录有关数据将会一并删除',
  DELETE: '删除',
}

const examQuestions = {
  SETTING_A: '设置',
  MARK_B: '分',
  MARKS: '分',
  MARK: '分数',
  QUANTITY: '数量',
  NO_SETTING_FOUND: '无有关设定',
  EDIT_QUESTIONS: '编辑考题',
  QUESTION_REPO_FOR_EXAM: '题库: ',
  SAVE_CONFIRM: '确认保存',
  CHANGE_SAVE: '更改保存',
  NUMBER_SELECTED_QUESTIONS_EQUAL_TO_QUANTITY_CHECK: '已选题数必须与设定题目数量吻合',
  PLS_ADJUST_QUANTITY: '请调整已选题数，或在考试系统配置页面调整已设题目数量。',
  QUESTIONS: '考题',
  SELECTED_B: '已选',
}

const examSetting = {
  MANAGE_STUDENT_ACCESS: '学生测试访问权管理',
  GENERATE_QUESTIONS: '生成考题',
  GENERATED_QUESTIONS: '已生成考题',
  MARK_PER_QUESTION: '每题分数',
  DELETE_INFO_EXAM_SETTING: '所有与此条记录有关数据（如有关测验等等）将会一并删除',
  STUDENT_ACCESS: '学生测试访问',
  SELECT_ALL: '勾选所有',
  UNSELECT_ALL: '去除所有勾选',
  STUDENTS_B: '学生',
  STUDENT_B: '学生',
}

const teacher = {
  TEACHER_PROFILE: '老师档案',
  BOD: '出生日期',
  TITLE: '头衔',
  MIDDLE_NAME: '中间名',
  USER_ROLE: '用户类别',
  DELETE_INFO_TEACHER: '所有与此条记录有关数据（如所属班级等等）将会一并删除',
  NAME: '姓名',
  VISITS_MONTHLY_OVERVIEW: '每月约课信息浏览',
  TOGGLE_MONTH_WEEK_VIEW: '切换视图至当前月份/星期',
  MARKS_B: '分数',
  NO_TEACHER_SELECTED: '未选择老师',
  NO_ORGANISATION_SELECTED: '未选择教育机构',
  NO_ORGANISATION_SUBJECT_CLASS_SELECTED: '未选择教育机构/班级',
  NO_STUDENTS_FOUND: '未发现符合条件的学生',
  APPLY_KEYWORD_TO_VISIT_LIST: '应用关键词至授课列表',
  VISIT_LIST_BASED_KEYWORDS: '约课列表（根据关键词）',
  SEARCH_VISIT_NAME: '搜索约课名称'
}

const student = {
  STUDENT_PROFILE: '学生档案',
  DELETE_INFO_STUDENT: '所有与此条记录有关数据（如所属班级等等）将会一并删除',
  ENROLLMENT: '注册',
  STUDENT_SINGLE: '学生',
  ENROLL_STUDENT_SEMESTER: '根据学生注册学期',
  ENROLLED_SEMESTERS: '已注册学期',
  VIEW_CLASS: '查看班级',
  DELETE_INFO_ENROLLMENT: '所有与此条记录有关数据（如有关学生等等）将会一并删除',
  STUDENT_SEMESTER: '学期注册',
  ENROLLED_CLASSES: '已注册班级',
  ENROLL_CLASS: '注册班级',
  NO_ENROLLMENTS_FOUND: '无注册记录',
  ENROLLED_COURSE_INFO: '已注册课程信息',
  REMOVE: '移除',
  UNIT_CODE: '科目号',
  COMPULSORY: '必修课',
  SUBJECT_IS_ENROLLED: '此课程已经注册',
  ADD_NOW: '新增',
  DELETE_INFO_SUBJECT_ENROLLMENT: '所有与此条记录有关数据（如已注册班级等等）将会一并删除',
  NO_RESULT_FOUND: '无数据',
  NO_STUDENT_SELECTED: '未选择学生',
  NO_SUBJECT_FOUND: '未发现有关课程',
  HISTORY_VISIT: '约课历史',
  ACTIVE_VISIT: '当前活跃课程',
  CHECKED_IN: '授课已登记',
  NOT_CHECK_IN: '授课未登记',
  CHECKED_OUT: '授课已完成',
  CHECKED_IN_BY_TEACHER: '老师已登记授课',
  NOT_CHECK_IN_BY_TEACHER: '老师未登记授课',
  CHECKED_OUT_BY_TEACHER: '老师已完成授课',
  STATUS: '状态',
  NO_HISTORY_FOUND: '无记录',
  NO_MARKED: '未评分',
  NO_FEEDBACK_YET: '未反馈',
  NO_DOCUMENT_UPLOADED_IN_THIS_VISIT: '当前无上传文件',
  VISIT_INFO: '详情',
  UPLOADED_FILES: '已上传文件',
}

const calendar = {
  YEAR: '学年',
  SCHOOL_YEAR: '学年',
  VIEW_SEMESTERS: '查看学期（大）',
  START_DATE: '起始日期',
  END_DATE: '结束日期',
  SCHOOL_SEMESTER: '学期（大）',
  SEMESTER: '学期（大）',
  VIEW_TERMS: '查看学期（小）',
  SCHOOL_TERM: '学期（小）',
  TERM: '学期（小）',
  VIEW_TERM_DATE: '查看学期（小）日期',
  SCHOOL_TERM_DATES: '学期（小）日期',
  TERM_DATE: '学期（小）日期',
  SEMESTER_INT: '大学期（大）数字编号',
  TERM_INT: '学期（小）数字编号',
}

const group = {
  ORGANISATION: '教育机构',
  ORGANISATION_NAME: '教育机构名称',
  VIEW_STUDENTS: '查看学生',
  VIEW_VISITS: '查看约课',
  STUDENT_IN_ORGANISATION: '教育机构学生',
  VISIT: '约课',
  VISITS: '约课',
  VISIT_NAME: '约课名称',
  UPDATED_TIME: '更新时间',
  ADDRESS: '地址',
  TEL: '电话号码',
  MOBILE: '手机号码',
  LONGITUDE: '经度',
  LATITUDE: '纬度',
  CONTACT_PERSON: '联系人',
  STUDENT_LIST: '学生列表',
  STUDENTS_SINGLE: '学生',
  TEACHER_SINGLE: '老师',
  SELECT_STUDENT: '选择学生',
  NO_STUDENTS_FOUND_PLEASE_ADD_STUDENT_ORG: '未找到学生，请优先将学生添加进相应组织！',
  CLEAR: '清除',
  TIME_START_IN: '起始时间于',
  OVER_8_HOURS_WARNING: '请注意，以上约课时长已超过8小时！',
  ENABLE_RECURRING_VISIT: '约课循环创建模式开启',
  SKIP_WEEKENDS: '跳过周末',
  RECURRENCE: '循环模式',
  DAILY: '每日',
  WEEKLY: '每周',
  MONTHLY: '每月',
  REPEAT_FOR: '循环',
  TIMES: '次',
  OF_EVERY: '每隔',
  DAY_S: '天',
  WEEK_S: '周',
  MONTH_S: '月',
  OVERWRITE_WARNING: '提示：批量创建新约课时间将会替代之前已创建的约课时间列表。',
  GENERATE_TIME_SLOTS: '创建新约课时间列表',
  NO_TIME_SLOTS: '目前无创建约课时间！',
  UPDATE_VISIT: '更新约课信息',
  CREATE_VISIT: '创建约课信息',
  SCHEDULE_TIME_SLOTS: '安排约课时间',
  TIME_SLOTS: '约课时间列表',
  GROUP_NAME: '集团名',
  VIEW_ORG: '查看组织',
  DELETE_INFO_GROUP: '所有与此条记录有关数据（如已创建组织等等）将会一并删除',
  VISITS_BY_ORG: '按组织搜索有关约课信息',
  CLEAR_ALL_MAKER: '关闭所有地图标识展开',
  APPLY: '应用',
  VIEW: '查看',
  CREATE_TIME: '创建时间',
  STAFF_NAME: '授课人员',
}

const notify = {
  UPDATED_B: '已更新',
  CREATED_B: '已创建',
  DELETED_B: '已删除',
  UPDATE_FAILED: '更新失败！请咨询网络管理员了解问题',
  CREATE_FAILED: '新增失败！请咨询网络管理员了解问题',
  DELETE_FAILED: '删除失败！请咨询网络管理员了解问题',
  NO_CLASS_SELECTED_FOR_AT_LEAST_ONE_SUBJECT: '已发现至少一门学科没有选择相应班级',
  NO_SUBJECTS_IS_FOUND_IN_SELECTED_DOMAIN: '已选科目当前无任何可注册课程',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...dashboard,
    ...yearLevel,
    ...subject,
    ...subjectClass,
    ...domain,
    ...question,
    ...questionType,
    ...questionCat,
    ...knowledgePoint,
    ...exam,
    ...examQuestions,
    ...examSetting,
    ...teacher,
    ...student,
    ...calendar,
    ...group,
    ...notify,
    lang: 'zh-CN',
    GO_BACK: '返回',
    WELCOME: '您好',
    CANCEL: '取消',
    PLEASE_CONTACT_ADMIN: '请联系系统管理员',
    SHOW_ALL_SUBJECTS: '显示所有科目',
    SHOW_ONLY_ENGLISH_MATH: '仅显示英语/数学',
    YEAR_LEVEL: '年级',
    FIRST_NAME: '名',
    LAST_NAME: '姓',
    SURNAME: '姓',
    ERROR: '失败',
    PLEASE_FILL_IN_ALL_FIELDS: '请确保所有空格处已填写',
    NO: '否',
    YES: '是',
    TODAY: '今天',
    PREV: '往前',
    NEXT: '往后',
    ABSENT_BETWEEN: '缺席于',
    CURRENT: '当前',
    REMINDER: '通知栏',
    VIEW_ALL: '查看全部',
    NEW_UNREAD_MSGS_FOUND: '封未读消息',
    ALL: '全部',
  }
};
