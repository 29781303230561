/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { composeImageUrlsInCartItems, ImageSize } from '../utilities/image-url-composer';
import { isNative, isWeb } from '../utilities/platform';
import atob from '../utilities/atob';
import RootStore from './root-store';

export default class UserStore {
  get isAuthed() {
    if (typeof window !== "undefined" && localStorage.USER_TOKEN && localStorage.USER_INFO)
      return true;
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) {
      delete localStorage.USER_TOKEN;
      delete localStorage.USER_INFO;
    }
  }

  userToken: string | null = null;

  loading: boolean = false;

  loadingVisit: boolean = false;

  loadingTable: boolean = false;

  visitChangeList: any = { totalCount: 0, data: []}

  msgList: any = { totalCount: 0, data: [] };

  msgNumber: number = 0;

  todayVisitData: any = { visitOrgNumber: 0, visitStudentNumber: 0, visitTeacherNumber: 0 };

  yearlyVisitData: any = {
    currentYearData: { visitOrgNumber: 0, visitStudentNumber: 0, visitTeacherNumber: 0 },
    lastYearData: { visitOrgNumber: 0, visitStudentNumber: 0, visitTeacherNumber: 0 }
  };

  todayFullList: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      userToken: observable,
      loadingVisit: observable,
      loading: observable,
      loadingTable: observable,
      visitChangeList: observable,
      msgList: observable,
      msgNumber: observable,
      todayFullList: observable,
      signIn: action,
      signOut: action,
      getMessageList: action,
      getNewMessageNumber: action,
      readMessage: action,
      getTodayVisitData: action,
      getYearlyVisitData: action,
      getTodayVisitFullList: action,
      getChangeRequestListByVisitId: action,
    });

    this.rootStore = rootStore;
  }

  setVisitTableLoading = (flag: boolean) => {
    this.loadingTable = flag;
  }


  signIn = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/SignIn`, userModel);
      const token = `Bearer ${res.data.token}`;
      console.log(res.data);
      this.userToken = res.data.token;
      localStorage.USER_TOKEN = token;
      let userInfo = {
        id: res.data.id,
        title: res.data.title,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        collegeId: res.data.collegeId,
        employerDefaultPassword: res.data.employerDefaultPassword,
      }
      localStorage.USER_INFO = JSON.stringify(userInfo);
      localStorage.CALENDAR = JSON.stringify({
        semester: res.data.currentSemesterId,
        startDate: res.data.startDate,
        endDate: res.data.endDate,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  signOut = () => {
    this.isAuthed = false;
    this.userToken = "";
    window.location.href = "/";
  };

  getMessageList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Message/GetMessageList/${take}/${skip}`);
      this.msgList = res.data;
      console.log("MSG List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getNewMessageNumber = async () => {
    try {
      const res = await Axios.get(`/api/Message/GetNewMessageNumber`);
      this.msgNumber = res.data;
      console.log("MSG Number", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  readMessage = async (id: number) => {
    try {
      const res = await Axios.get(`/api/Message/ReadMessage/${id}`);
      console.log("Read", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getTodayVisitData = async () => {
    try {
      const res = await Axios.get(`/api/Visit/GetTodayVisitData`);
      console.log("Today Visit Data", res.data);
      this.todayVisitData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getYearlyVisitData = async () => {
    try {
      const res = await Axios.get(`/api/Visit/GetYearlyVisitData`);
      console.log("Yearly Visit Data", res.data);
      this.yearlyVisitData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getTodayVisitFullList = async (time: any) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.post('/api/Visit/GetTodayVisitFullList',time);
      console.log("Today Full List Data", res.data);
      this.todayFullList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }

  getChangeRequestListByVisitId = async(id: number) => {
    this.loadingTable = true;
    try {
      const res = await Axios.get(`/api/Visit/GetChangeRequestListByVisitId/${id}`);
      this.visitChangeList.data = res.data;
      this.visitChangeList.totalCount = res.data.length;
      console.log("Change Request List: ", res.data);
      return Promise.resolve(res.data)
    } catch(err) {
      return Promise.reject(err);
    } finally {
      this.loadingTable = false;
    }
  }

}
