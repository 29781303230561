/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class StudentStore {

  loading: boolean = false;

  loadingVisit: boolean = false;

  loadingTable: boolean = false;

  studentProfileList: any = { totalCount: 0, data: [] };

  visitHistoryList: any = { totalCount: 0, data: [] };

  visitCurrentList: any = { totalCount: 0, data: [] };

  visitListByTraineeship: any = [];

  traineeshipList: any = [];

  sProfile: any = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingVisit: observable,
      loadingTable: observable,
      sProfile: observable,
      studentProfileList: observable,
      visitHistoryList: observable,
      visitCurrentList: observable,
      visitListByTraineeship: observable,
      traineeshipList: observable,
      createStudentProfile: action,
      updateStudentProfile: action,
      deleteStudentProfile: action,
      updateStudentSemester: action,
      enrollStudentSemester: action,
      enrollStudentClass: action,
      deleteStudentClass: action,
      getVisitListByStudentId: action,
      getVisitListByTraineeshipId: action,
      getTraineeshipListByYearAndTraineeId: action,
    });

    this.rootStore = rootStore;
  }

  // set loading
  setVisitTableLoading = (flag: boolean) => {
      this.loadingTable = flag;
  }

  /* ========== STUDENT CRUD ========== */
  getStudentProfileList = async (take: number, skip: number, reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Student/GetStudentProfileList/${take}/${skip}`, reqModel);
      this.studentProfileList = res.data;
      console.log("Student List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentProfileById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Student/GetStudentProfileById/${id}`);
      console.log("Student Profile", res.data);
      this.sProfile = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createStudentProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Student/CreateStudentProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateStudentProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Student/UpdateStudentProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteStudentProfile = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Student/DeleteStudentProfile/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END STUDENT CRUD ========== */
  /* ========== STUDENT SEMESTER CRUD ========== */

  enrollStudentSemester = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Student/EnrollStudentSemester`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateStudentSemester = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Student/UpdateStudentSemester`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteStudentSemester = async (id: any) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Student/DeleteStudentSemester/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* ========== END STUDENT SEMESTER CRUD ========== */
  /* ========== ENROLLMENT CRUD ========== */

  enrollStudentClass = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Student/EnrollStudentClass`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteStudentClass = async (id: any) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Student/DeleteStudentClass/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END ENROLLMENT CRUD ========== */
  /* ========== VISIT LIST ========== */

  getVisitListByStudentId = async (id: number, isHistory: boolean, take: number, skip: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Student/GetVisitListByStudentId/${id}/${isHistory}/${take}/${skip}`);
      if (isHistory) this.visitHistoryList = res.data;
      else this.visitCurrentList = res.data;
      console.log("Student Visit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }

  getVisitListByTraineeshipId = async (traineeshipId: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByTraineeshipId/${traineeshipId}`);
      this.visitListByTraineeship = res.data;
      console.log("Student Visit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }

  getTraineeshipListByYearAndTraineeId = async (traineeId: number, year: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Traineeship/getTraineeshipListByYearAndTraineeId/${traineeId}/${year}`);
      this.traineeshipList = res.data;
      console.log("Traineeship List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
