/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { DocumentType, FileCategory } from '../constants/options';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class FileStore {

  loading: boolean = false;

  uploadingID: boolean = false;

  uploadingDecorat: boolean = false;

  fileUrl: string = "";

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      uploadingID: observable,
      uploadingDecorat: observable,
      fileUrl: observable,
      getFile: action,
      uploadFile: action,
      deleteFile: action,
    });

    this.rootStore = rootStore;
  }

  /* ========== FILE CRUD ========== */
  getFile = async (type: DocumentType, category: FileCategory, id: number, name: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/File/GetFile/${type}/${category}/${id}/${name}`, { responseType: 'blob' });
      this.fileUrl = res.data;
      console.log("Get File Url", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  uploadFile = async (type: DocumentType, category: FileCategory, id: number, formData: FormData) => {
    if(category == FileCategory.FORM_PERSONAL_ID) this.uploadingID = true;
    else if(category == FileCategory.FORM_SIGNATURE) this.uploadingDecorat = true;
    else this.loading = true;
    try {
      const res = await Axios.post(`/api/File/UploadFile/${type}/${category}/${id}`, formData);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      if(category == FileCategory.FORM_PERSONAL_ID) this.uploadingID = false;
      else if(category == FileCategory.FORM_SIGNATURE) this.uploadingDecorat = false;
      else this.loading = false;
    }
  }

  deleteFile = async (type: DocumentType, id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/File/DeleteFile/${type}/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* ========== END ROLE CRUD ========== */
}
