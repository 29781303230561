import { observable, action, makeObservable } from 'mobx';
import UserStore from './user-store';
import AcademicStore from './academic-store';
import CalendarStore from './calendar-store';
import QuestionStore from './question-store';
import TeacherStore from './teacher-store';
import PermissionStore from './permission-store';
import ExamStore from './exam-store';
import StudentStore from './student-store';
import GroupStore from './group-store';
import AdminStore from './admin-store';
import TraineeshipStore from './traineeship-store';
import FileStore from './file-store';

interface WebNotification {
  message: string,
  options?:
  {
    variant: 'success' | 'error' | 'info' | 'warning'
  },
}

export default class RootStore {
  testVal = 'TEST_VALUE';

  destination = 'Select';

  notification: WebNotification = {
    message: "",
    options: {
      variant: "info"
    }
  }

  userStore;
  
  academicStore;

  calendarStore;

  questionStore;

  teacherStore;

  permissionStore;

  studentStore;

  examStore;

  groupStore;

  adminStore;

  traineeshipStore;

  fileStore;

  constructor() {
    makeObservable(this, {
      testVal: observable,
      destination: observable,
      notification: observable,
      notify: action,
    });
    this.userStore = new UserStore(this);
    this.academicStore = new AcademicStore(this);
    this.calendarStore = new CalendarStore(this);
    this.questionStore = new QuestionStore(this);
    this.teacherStore = new TeacherStore(this);
    this.permissionStore = new PermissionStore(this);
    this.studentStore = new StudentStore(this);
    this.examStore = new ExamStore(this);
    this.groupStore = new GroupStore(this);
    this.adminStore = new AdminStore(this);
    this.traineeshipStore = new TraineeshipStore(this);
    this.fileStore = new FileStore(this);
  }

  notify(msg: string, level?: 'success' | 'error' | 'info' | 'warning') {
    if (level) {
      this.notification = {
        message: msg,
        options: {
          variant: level,
        },
      };
    } else {
      this.notification = {
        message: msg,
      };
    }
  }
}
