/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { DateUtils } from '../utilities/date';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class TeacherStore {

  loading: boolean = false;

  loadingTrainee: boolean = false;

  loadingVisit: boolean = false;

  loadingStudentList: boolean = false;

  loadingWorkingSummary: boolean = false;

  loadingWorkingDetail: boolean = false;

  downloadExcel: boolean = false;

  teacherProfileList: any = { totalCount: 0, data: [] };

  teacherProfileFullList: any = { totalCount: 0, data: [] };

  teacherProfile: any | null = null;

  visitHistoryList: any = { totalCount: 0, data: [] };

  visitCurrentList: any = { totalCount: 0, data: [] };

  studentListByStaff: any = [];

  allVisitList: any = [];

  allTraineeList: any = { totalCount: 0, data: [] };

  traineeByGroupList: any = [];

  visitListByTrainee: any = [];

  workingSummaryList: any = [];

  workingDetailList: any = [];

  excelFile: any = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingTrainee: observable,
      loadingVisit: observable,
      loadingStudentList: observable,
      loadingWorkingSummary: observable,
      loadingWorkingDetail: observable,
      teacherProfileList: observable,
      teacherProfile: observable,
      visitHistoryList: observable,
      visitCurrentList: observable,
      studentListByStaff: observable,
      allTraineeList: observable,
      traineeByGroupList: observable,
      allVisitList: observable,
      visitListByTrainee: observable,
      workingSummaryList: observable,
      workingDetailList: observable,
      excelFile: observable,
      downloadExcel: observable,
      getTeacherProfileList: action,
      getTeacherProfileById: action,
      createTeacherProfile: action,
      updateTeacherProfile: action,
      deleteTeacherProfile: action,
      getVisitListByStaffId: action,
      getStudentListGroupByOrganisation: action,
      getVisitListByOrgIdAndTrainerId: action,
      getTraineeListByTrainerIdAndYear: action,
      getTraineeListGroupByTrainerIdAndYear: action,
      getVisitListByTraineeIdAndTrainerId: action,
      getTrainerWorkingHoursDetailByTrainerId: action,
      getTrainerWorkingHoursOverview: action,
      generateTrainerWorkingHoursOverviewExcel: action,
    });

    this.rootStore = rootStore;
  }

  /* ========== TEACHER CRUD ========== */
  getTeacherProfileList = async (take: number, skip: number, storedInFullList?: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetTeacherProfileList/${take}/${skip}`);
      if(storedInFullList) this.teacherProfileFullList = res.data;
      else this.teacherProfileList = res.data;
      console.log("Teacher Profile List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getTeacherProfileById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetTeacherProfileById/${id}`);
      this.teacherProfile = res.data;
      console.log("Teacher Profile", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createTeacherProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Teacher/CreateTeacherProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateTeacherProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Teacher/UpdateTeacherProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteTeacherProfile = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Teacher/DeleteTeacherProfile/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END TEACHER CRUD ========== */

  /* ========== START TEACHER PROFILE PAGE ========== */
  getVisitListByStaffId = async (id: number, isHistory: boolean, take: number, skip: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetVisitListByStaffId/${id}/${isHistory}/${take}/${skip}`);
      if (isHistory) this.visitHistoryList = res.data;
      else this.visitCurrentList = res.data;
      console.log("Teacher Visit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }

  getStudentListGroupByOrganisation = async (id: number, semesterId: number) => {
    this.loadingStudentList = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetStudentListGroupByOrganisation/${id}/${semesterId}`);
      this.studentListByStaff = res.data;
      console.log("Teacher Student List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingStudentList = false;
    }
  }

  getVisitListByOrgIdAndTrainerId = async (id: number, orgId: number, subjectClassId: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetVisitListByOrgIdAndTrainerId/${id}/${orgId}/${subjectClassId}`);
      this.allVisitList = res.data;
      console.log("Teacher All List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }
  
  updateTrainerUnitLink = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Teacher/UpdateTrainerUnitLink`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  
    updateTrainerUnitsLink = async (reqModel: any) => {
      this.loading = true;
      try {
        const res = await Axios.post(`/api/Teacher/UpdateTrainerUnitsLink`, reqModel);
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    }
  
  getTraineeListByTrainerIdAndYear = async (trainerId: number, year: string, take: number, skip: number) => {
    this.loadingTrainee = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetTraineeListByTrainerIdAndYear/${trainerId}/${year}/${take}/${skip}`);
      this.allTraineeList = res.data;
      console.log("Teacher Trainee List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingTrainee = false;
    }
  }

  getTraineeListGroupByTrainerIdAndYear = async (trainerId: number, year: string) => {
    this.loadingTrainee = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetTraineeListGroupByOrgByTrainerIdAndYear/${trainerId}/${year}`);
      this.traineeByGroupList = res.data;
      console.log("Teacher Trainee List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingTrainee = false;
    }
  }

  getVisitListByTraineeIdAndTrainerId = async (trainerId: number, traineeId: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Teacher/GetVisitListByTraineeIdAndTrainerId/${trainerId}/${traineeId}`);
      this.visitListByTrainee = res.data;
      console.log("Teacher Trainee Visit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }
  /* ========== END TEACHER PROFILE PAGE CRUD ========== */

  /* ========== START WORKING SUMMARY ========== */
  getTrainerWorkingHoursOverview = async (reqModel: any) => {
    this.loadingWorkingSummary = true;
    try {
      const res = await Axios.post(`/api/Teacher/GetTrainerWorkingHoursOverview`, reqModel);
      this.workingSummaryList = res.data;
      console.log("Working Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingWorkingSummary = false;
    }
  }

  getTrainerWorkingHoursDetailByTrainerId = async (reqModel: any) => {
    this.loadingWorkingDetail = true;
    try {
      const res = await Axios.post(`/api/Teacher/GetTrainerWorkingHoursDetailByTrainerId`, reqModel);
      this.workingDetailList = res.data;
      console.log("Working Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingWorkingDetail = false;
    }
  }

  generateTrainerWorkingHoursOverviewExcel = async (reqModel: any) => {
    this.downloadExcel = true;
    try {
      const requestURL = `/api/Teacher/GenerateTrainerWorkingHoursOverviewExcel`;
      const res = await Axios.post(requestURL, reqModel, { responseType: 'blob' });
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = `${DateUtils.onConvertDateFormat(
        reqModel.startDate).replace('/', '-').replace('/', '-')}_${DateUtils.onConvertDateFormat(
          reqModel.endDate.split('T')[0]).replace('/', '-').replace('/', '-')}_TrainerWorkingHoursOverviewReport.xls`
      console.log(fileName);
      
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href)
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloadExcel = false;
    }
  }
  /* ========== END WORKING SUMMARY ========== */

}
