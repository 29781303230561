/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class ExamStore {

  loading: boolean = false;

  examSettingLoading: boolean = false;

  getAccessLoading: boolean = false;

  examList: any = { totalCount: 0, data: [] };

  examSettingList: any = [];

  examQuestionList: any = [];

  tempQuestionList: any = [];

  fullQuestionList: any = [];

  eligibleStudentList: any = [];

  eligibleStudentCount: number = 0;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      examSettingLoading: observable,
      getAccessLoading: observable,
      examList: observable,
      examSettingList: observable,
      examQuestionList: observable,
      tempQuestionList: observable,
      fullQuestionList: observable,
      eligibleStudentList: observable,
      eligibleStudentCount: observable,
      // EXAM
      createExam: action,
      updateExam: action,
      deleteExam: action,
      getExamList: action,
      // EXAM SETTING
      getExamSettingByExamId: action,
      createExamSetting: action,
      updateExamSetting: action,
      deleteExamSetting: action,
      generateExamQuestion: action,
      editExamAccessSetting: action,
      editExamQuestion: action,
      getExamQuestionListByExamId: action,
      getFullQuestionListByExamId: action,
      getEligibleStudentListByExamId: action,
    });

    this.rootStore = rootStore;
  }

  /* ========== EXAM OVERVIEW CRUD ========== */
  getExamList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetExamList/${take}/${skip}`);
      this.examList = res.data;
      console.log("Exam List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createExam = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Exam/CreateExam`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateExam = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Exam/UpdateExam`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteExam = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Exam/DeleteExam/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* ========== END EXAM OVERVIEW CRUD ========== */
  /* ========== EXAM SETTING CRUD ========== */

  getExamSettingByExamId = async (id: number) => {
    this.examSettingLoading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetExamSettingByExamId/${id}`);
      this.examSettingList = res.data;
      console.log("Exam Setting List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.examSettingLoading = false;
    }
  }

  createExamSetting = async (reqModel: any) => {
    this.examSettingLoading = true;
    try {
      const res = await Axios.post(`/api/Exam/CreateExamSetting`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.examSettingLoading = false;
    }
  }

  updateExamSetting = async (reqModel: any) => {
    this.examSettingLoading = true;
    try {
      const res = await Axios.put(`/api/Exam/UpdateExamSetting`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.examSettingLoading = false;
    }
  }

  deleteExamSetting = async (id: any) => {
    this.examSettingLoading = true;
    try {
      const res = await Axios.delete(`/api/Exam/DeleteExamSetting/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.examSettingLoading = false;
    }
  }

  /* ========== END EXAM CRUD ========== */

  editExamAccessSetting = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Exam/EditExamAccessSetting`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  editExamQuestion = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Exam/EditExamQuestion`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getExamQuestionListByExamId = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetExamQuestionListByExamId/${id}`);
      this.examSettingList = res.data;
      console.log("Exam Question List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  generateExamQuestion = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/GenerateExamQuestion/${id}`);
      this.examQuestionList = res.data;
      console.log("Generate Exam Question", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getFullQuestionListByExamId = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetFullQuestionListByExamId/${id}`);
      this.fullQuestionList = res.data;
      console.log("Full Question List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getEligibleStudentListByExamId = async (id: number) => {
    this.eligibleStudentCount = 0;
    this.getAccessLoading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetEligibleStudentListByExamId/${id}`);
      this.eligibleStudentList = res.data;
      console.log("Eligible Student List", res.data);
      for (var classCode of res.data) {
        this.eligibleStudentCount += classCode.studentList.length;
      }
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.getAccessLoading = false;
    }
  }
}
